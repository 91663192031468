import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { useContext } from 'react';
import PicklistContext from '../../contexts/PicklistContext';
import ProductItem from './ProductItem';
import getProductKey from '../../utilities/getProductKey';
import { Product } from '../../constants/Picklist';
import Button from '@mui/material/Button';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';

export default function ProductList() {
  const {
    currentPicklist,
    scanCompletedProducts,
    clearBagScans,
    currentOrderId,
    currentDeliveryDate
  } = useContext(PicklistContext);

  const amProducts = currentPicklist.filter((product: Product) => product.is_am_packed);
  const pmProducts = currentPicklist.filter((product: Product) => !product.is_am_packed);

  const getProductList = (products: Product[]) => {
    return products.reduce((acc: JSX.Element[], product: Product, productIndex) => {
      for (let i = 0; i < product.product_quantity; i++) {
        const key = getProductKey(product.product_id || 0, i);
        acc.push(
          <ProductItem
            product={product}
            productKey={key}
            key={key}
            index={i}
            isLast={products.length - 1 === productIndex}
          />
        );
      }
      return acc;
    }, []);
  };

  return (
    <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
      <h4>{`Delivery Date: ${currentDeliveryDate ? currentDeliveryDate : ''}`}</h4>
      <p>{`Order ID: ${currentOrderId ? currentOrderId : ''}`}</p>
      <div>
        {pmProducts.length > 0 && (
          <Accordion defaultExpanded>
            <AccordionSummary>PM Products ({pmProducts.length})</AccordionSummary>
            <AccordionDetails>{getProductList(pmProducts)}</AccordionDetails>
          </Accordion>
        )}
        {amProducts.length > 0 && (
          <Accordion defaultExpanded>
            <AccordionSummary>AM Products ({amProducts.length})</AccordionSummary>
            <AccordionDetails>{getProductList(amProducts)}</AccordionDetails>
          </Accordion>
        )}
      </div>
      <ListItem
        secondaryAction={
          Array.isArray(scanCompletedProducts.bag) &&
          scanCompletedProducts.bag.length > 0 && (
            <Button size="small" onClick={clearBagScans}>
              Clear
            </Button>
          )
        }
      >
        <ListItemText
          primary="Bag"
          secondary={
            <Typography variant="caption">
              Number of bags scanned:{' '}
              {Array.isArray(scanCompletedProducts.bag) ? scanCompletedProducts.bag.length : 0}
            </Typography>
          }
        />
      </ListItem>
    </List>
  );
}
